.test
    // display: flex
    &__upper
        position: relative
        &:after
            position: absolute
            content: ''
            width: 0
            height: 0
            left: 0
            top: 50vh
            border-top: 10vh solid #ffa501
            border-right: 35vw solid transparent
            // opacity: 0.7

        background-color: #ffa501
        // width: 40vw
        height: 50vh
        // opacity: .7
    &__lower
        background-color: #BCDDF0
        // width: 60vw
        height: 50vh
        // opacity: .7
        &:after
            position: absolute
            content: ''
            width: 0
            height: 0
            // left: 0
            // top: 50vh
            bottom: 50vh
            right: 15vw
            border-bottom: 10vh solid #BCDDF0
            border-left: 35vw solid transparent
            // opacity: 0.7