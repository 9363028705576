.about
	min-height: 100dvh
	transition: all 0.5s ease-in-out
	background-color:hsla(0,0%,9%,1)
	background-image: radial-gradient(at 100% 0%, hsla(38,100%,50%,.5) 0px, transparent 50%),radial-gradient(at 71% 43%, hsla(267,72%,63%,.5) 0px, transparent 50%),radial-gradient(at 63% 31%, hsla(240,0%,30%,.5) 0px, transparent 50%),radial-gradient(at 0% 100%, hsla(324,84%,65%,.5) 0px, transparent 50%),radial-gradient(at 86% 88%, hsla(194,100%,48%,.5) 0px, transparent 50%),radial-gradient(at 15% 66%, hsla(201,63%,83%,.5) 0px, transparent 50%),radial-gradient(at 30% 25%, hsla(51,98%,62%,.5) 0px, transparent 50%)


	&__wrapper
		display: flex
		justify-content: space-between

.description
	grid-area: description
	&__title
		position: relative
		font-size: 20px
		font-weight: 700
		background: transparent
		will-change: contents
		opacity: 0
		// z-index: 1
		animation: fadeInUp 1s 0.5s forwards
		&::before
			@include beforeOrangeCircle
	&__subtitle
		margin-top: 20px
		font-size: 36px
		font-weight: 700
		will-change: contents
		opacity: 0
		animation: fadeInUp 1s 1s forwards
	&__text
		font-size: 18px
		margin-top: 20px
		will-change: contents
		opacity: 0
		animation: fadeInUp 1s 1.5s forwards
	@for $i from 1 through 3
		p:nth-child(#{$i})
			will-change: contents
			opacity: 0
			animation: fadeInUp 1s 0.5s + .8s*$i forwards
.skills
	grid-area: skills
	display: flex
	flex-wrap: wrap
	margin-left: 80px

	&__item
		width: 260px
		margin: 0 20px 20px 0
		padding: 15px
		border: 2px solid $orange
		border-radius: 15px

	@for $i from 1 through 3
		&__item:nth-child(#{$i})
			will-change: contents
			opacity: 0
			animation: fadeIn 1s 3s + .5s * $i ease forwards
	&__title
		display: flex
		justify-content: space-between
		align-items: center
		h3
			font-size: 22px
			font-weight: 700			
	&__icon
		width: 30px
		height: 30px
		position: relative
		margin-right: 10px
		z-index: 0
		&::before
			@include beforeOrangeCircle

@media (max-width:  2600px)
	.about
		.container
			width: 70%
			padding-top: 100px
		&__wrapper
			display: flex
			justify-content: space-between
	.description
		width: 50%
	.skills
		width: 50%
		align-items: center
		&__item
			width: 100%
			margin: 20px 20px 0 0
			padding: 5px
		&__title
			justify-content: center

@media (max-width: 1506px)
	.about
		.container
			padding-top: 50px

@media (max-width: 1350px)
	.about
		.container
			width: 85%
			padding-left: 80px

@media (max-width: 1085px)
	.about
		.container
			padding: 20px 0 0 80px

	.skills
		margin: 0

@media (max-width: 955px)
	.about
		.container
			width: 70%
			padding-left: 80px
	.about__wrapper
		flex-direction: column
	.description
		width: 100%
	.skills
		width: 100%

@media (max-width:  768px)
	.about
		&__wrapper
			display: flex
			flex-wrap: wrap
			width: 100%
			margin: 0 auto
		.container
			padding: 60px 0 0 0
	.description
		width: 90%
	.skills
		margin: 0
		&__item
			margin: 10px 0
	
@media (max-width: 320px)
	.about
		.container
			width: 85%
	.skills
		margin: 0
		&__item
			margin: 10px 0